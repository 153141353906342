import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../client";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import "./BlogSinglePost.css";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function SinglePost() {
  const [singlePost, setSinglePost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
            title,
            _id,
            slug,
            mainImage{
                asset->{
                    _id,
                    url
                }
            },
            body
            
        }`
      )
      .then((data) => setSinglePost(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!singlePost) return <div>Loading...</div>;

  return (
    <main>
      <article>
        <header>
          <div>
            <div>
              <h1 className="post_title">{singlePost.title}</h1>
              {/* <div>
                <img
                  src={urlFor(singlePost.authorImage).url()}
                  alt={singlePost.name}
                />
                <p>{singlePost.name}</p>
              </div> */}
            </div>
          </div>

          {/* uncomment below if you want to use images in blog posts */}

          {/* <img
            className="post_image"
            src={singlePost.mainImage.asset.url}
            alt={singlePost.title}
            style={{ height: "400px" }}
          /> */}
        </header>
        <div className="body_container">
          <BlockContent
            className="content"
            blocks={singlePost.body}
            projectId="4oiyt1jx"
            dataset="production"
          />
        </div>
      </article>
    </main>
  );
}
