import React from "react";
import "./AboutPage.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import CloudPractitioner from "../images/aws_certified_cloud_practitioner.png";
import SysOpsAss from "../images/AWS-Certified_Sysops-Administrator_Associate.png";
import DevAss from "../images/AWS-Certified_Developer_Associate.png";
import SolutionsAss from "../images/AWS-Certified_Solutions-Architect_Associate.png";
import AzureFund from "../images/microsoft-certified-azure-fundamentals.png";
import GceAssociate from "../images/google_cloud_engineer_associate.png";
import Djangologo from "../images/djangologo.png";
import Reactlogo from "../images/reactlogo.png";
import expresslogo from "../images/expresslogo.png";
import jslogo from "../images/pngwing.com.png";
import jsflatlogo from "../images/javascript_flat_logo.png";
import sqllogo from "../images/sqllogo.png";
import pythonlogo from "../images/pythonlogo.png";

const AboutPage = () => {
  return (
    <div className="about_container">
      <div className="page_title">
        <h1>About</h1>
      </div>

      <div className="about_me">
        <p>
          I'm a former Petroleum Geologist & Geophysicist that successfully
          transitioned into a career in Cloud and DevOps Engineering. I
          currently work as an AWS Cloud Engineer for Bluespace Solutions.{" "}
        </p>

        <p>
          My journey into programming and computers started in 2015 when I
          decided to learn basic HTML/CSS/JS for pet projects. I have since
          enrolled and completed several mini courses at{" "}
          <a href="https://www.codecademy.com/">CodeCademy</a> and{" "}
          <a href="https://freecodecamp.org">FreeCodeCamp</a> to keep learning.{" "}
          I'm a "Pythonista" at heart, however I also speak JavaScript.
        </p>

        <p>
          In addition to working full time, I am also a full time student,
          working towards getting a{" "}
          <strong>
            <a href="https://eecs.oregonstate.edu/academic/online-cs-postbacc">
              B.S. in Computer Science
            </a>
          </strong>{" "}
          at Oregon State University (Class of 2021).{" "}
          <strong>GO BEAVERS!</strong>
        </p>

        <p>
          I work really hard, learn very fast, and I'm always open to sharing
          what I know. Want to chat? <a href="/contact">Send me a note.</a>
        </p>
        <br></br>
        <h5>Languages and Frameworks</h5>
        <div className="languages">
          <img src={Djangologo} alt="django" />

          <img src={Reactlogo} alt="react native" />
          <img src={sqllogo} alt="react native" />
          <img src={jsflatlogo} alt="javascript" />
          <img src={pythonlogo} alt="python" />
        </div>
        <br></br>
        <br></br>
        <h5>Certifications</h5>
        <div className="certifications">
          <img
            src={CloudPractitioner}
            alt="aws cloud practitioner certification"
          />
          <img
            src={SolutionsAss}
            alt="aws solutions architect associate certification"
          />
          <img src={SysOpsAss} alt="aws sysops associate certification" />
          <img src={DevAss} alt="aws developer associate certification" />
          <img src={AzureFund} alt="azure fundamentals certification" />
          {/* <img
            src={GceAssociate}
            alt="google cloud engineer associate certification"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
