import "./App.css";
import BottomBar from "./components/BottomBar";
import TopBar from "./components/TopBar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import BlogPage from "./pages/BlogPage";
import ContactPage from "./pages/ContactPage";
import ProjectsPage from "./pages/ProjectsPage";
import AboutPage from "./pages/AboutPage";
import SinglePost from "./pages/BlogSinglePost";

function App() {
  return (
    <Router>
      <TopBar />

      <Switch>
        <Route path="/" component={HomePage} exact />
        <Route path="/about" component={AboutPage} exact />
        <Route path="/blog" component={BlogPage} exact />
        <Route path="/blog/:slug" component={SinglePost} />
        <Route path="/contact" component={ContactPage} exact />
        <Route path="/projects" component={ProjectsPage} exact />
      </Switch>

      <BottomBar />
    </Router>
  );
}

export default App;
