import React from 'react'
import HeroSection from '../components/HeroSection'

const HomePage = () => {
    return (
        <div>
            <HeroSection />
        </div>
    )
}

export default HomePage
