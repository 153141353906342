import React from "react";
import Container from "react-bootstrap/Container";
import RainDrops from "../images/raindrops_smaller.mp4";
import "./HeroSection.css";

const HeroSection = () => {
  return (
    <div>
      <div className="hero_container">
        <div className="hero_bg">
          <video
            className="video_bg"
            autoPlay
            loop
            muted
            src={RainDrops}
            type="video/mp4"
          />
        </div>
        <div className="hero_content">
          <div className="hero_title">Hi, I'm Bolu.</div>
          <div className="hero_blurb">
            Aspiring Cloud Ninja & Software Engineer.
          </div>
          <div className="hero_info">
            Learn more <a href="/about">about me</a>, read some musings
            on my <a href="/blog">blog</a>, and see{" "}
            <a href="/projects">projects</a> I've worked on.
            {/* <a href="/projects">projects</a> I've worked on. or explore my{" "}
            <a href="/blog">blog</a>, where I muse about lessons learned as a
            career transitioner. */}
          </div>

          <div className="hero_social">
            <a
              href="https://instagram.com/itsjustbolu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fab fa-instagram"></i>
            </a>

            <a
              href="https://www.linkedin.com/in/bolu-owolana/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fab fa-linkedin-in"></i>
            </a>

            <a
              href="https://github.com/itsjustbolu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fab fa-github"></i>
            </a>

            <a
              href="https://dev.to/itsjustbolu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fab fa-dev"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
