import React, { useState, useEffect } from "react";
import "./BlogPage.css";
import sanityClient from "../client";
import { Link } from "react-router-dom";

export default function BlogPage() {
  const [postData, setPost] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
        title,
        slug,
        publishedAt
      }`
      )
      .then((data) => setPost(data))
      .catch(console.error);
  }, []);

  return (
    <div className="blog_container">
      <div className="page_title">
        <h1>Blog</h1>
      </div>

      <div className="blog_blurb"> Musings from a wandering mind. </div>

      {postData &&
        postData.map((post, index) => (
          <article>
            <Link to={"/blog/" + post.slug.current} key={post.slug.current}>
              <ul>
                <span className="blog_posts" key={index}>
                  {post.title}
                </span>
              </ul>
            </Link>
          </article>
        ))}
    </div>
  );
}

// class BlogPage extends React.Component {
//   render() {
//     return (
//       <div className="blog_container">
//         <div className="page_title">
//           <h1>Blog</h1>
//         </div>
//         <div className="blog_blurb"> My collection of thoughts. </div>

//         <div className="blog_posts">Coming Soon</div>
//       </div>
//     );
//   }
// }

// export default BlogPage;
