import React from 'react'
import './BottomBar.css'

const BottomBar = () => {

    var today = new Date()
    var currentYear = today.getFullYear()

    return (
        <div className="footer">
            © {currentYear} Bolu Owolana. All Rights Reserved.
        </div>
    )
}

export default BottomBar
