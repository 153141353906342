import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "./TopBar.css";

const TopBar = () => {
  return (
    <div>
      <Navbar className="navigation_bar" expand="lg">
        <Navbar.Brand id="nav_brand" href="/">
          BOLU OWOLANA<i class="fas fa-cloud"></i>{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span>
            <i class="fas fa-bars"></i>
          </span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link id="nav_links" href="/about">
              About
            </Nav.Link>
            <Nav.Link id="nav_links" href="/blog">
              Blog
            </Nav.Link>
            <Nav.Link id="nav_links" href="/projects">
              Projects
            </Nav.Link>
            <Nav.Link id="nav_links" href="/contact">
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default TopBar;
