import React from "react";
// import CardDeck from "react-bootstrap/CardDeck";
import Card from "react-bootstrap/Card";
// import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import "./ProjectPage.css";

const ProjectsPage = () => {
  return (
    <div className="project_container">
      <div className="page_title">
        <h1>Projects</h1>
      </div>
      <div className="project_blurb">
        A few fun projects I decided to work on in my spare time.
      </div>

      <div className="card_container">
        <Card
          className="card"
          style={{ width: "22rem", border: "orange 2px solid" }}
        >
          {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
          <Card.Body className="card_body">
            <Card.Title style={{ fontWeight: "bolder" }}>
              Portfolio Website
            </Card.Title>
            <Card.Text>
              This website which I'm using to showcase myself. Using Sanity.io
              as the content management system for the blog.{" "}
              <div className="tech">
                Technologies: ReactJS, Sanity, HTML, CSS.
              </div>
            </Card.Text>
            {/* <Button
              style={{
                backgroundColor: "orange",
                color: "black",
                border: "orange 2px solid",
              }}
              href="https://itsjustbolu.github.io/Weather_App/"
              target="_blank"
            >
              Blog
            </Button>{" "} */}
            <Button
              style={{
                backgroundColor: "orange",
                color: "black",
                border: "orange 2px solid",
              }}
              href="https://itsjustbolu.com"
              target="_blank"
            >
              Demo
            </Button>{" "}
            <Button
              style={{
                backgroundColor: "orange",
                color: "black",
                border: "orange 2px solid",
              }}
              href="https://github.com/itsjustbolu/PortfolioSite_React"
              target="_blank"
            >
              Github
            </Button>
          </Card.Body>
        </Card>

        <Card
          className="card"
          style={{ width: "22rem", border: "orange 2px solid" }}
        >
          {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
          <Card.Body className="card_body">
            <Card.Title style={{ fontWeight: "bolder" }}>
              Ludo Property Management
            </Card.Title>
            <Card.Text>
              A website for a property mgt company to show listings, accept
              leasing applications, and payments. Also deployed on AWS.
              <div className="tech">
                Technologies: ReactJS, HTML, CSS, AWS S3.
              </div>
            </Card.Text>
            {/* <Button
              style={{
                backgroundColor: "orange",
                color: "black",
                border: "orange 2px solid",
              }}
              href="https://itsjustbolu.github.io/Weather_App/"
              target="_blank"
            >
              Blog
            </Button>{" "} */}
            <Button
              style={{
                backgroundColor: "orange",
                color: "black",
                border: "orange 2px solid",
              }}
              href="https://ludopm.com"
              target="_blank"
            >
              Demo
            </Button>{" "}
            <Button
              style={{
                backgroundColor: "orange",
                color: "black",
                border: "orange 2px solid",
              }}
              href="https://github.com/itsjustbolu/LudoPM_Website"
              target="_blank"
            >
              Github
            </Button>{" "}
            <Button
              style={{
                backgroundColor: "orange",
                color: "black",
                border: "orange 2px solid",
              }}
              href="http://ludo-pm-website-cloud-demo.s3-website.us-east-2.amazonaws.com"
              target="_blank"
            >
              AWS
            </Button>
          </Card.Body>
        </Card>

        <Card
          className="card"
          style={{ width: "22rem", border: "orange 2px solid" }}
        >
          {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
          <Card.Body className="card_body">
            <Card.Title style={{ fontWeight: "bolder" }}>
              Realty Analysis
            </Card.Title>
            <Card.Text>
              A web app used to calculate investment metrics such as Cash Flow,
              ROI, IRR, mortgages, etc for real estate investments.
              <div className="tech">Technologies: JavaScript, HTML, CSS.</div>
            </Card.Text>
            {/* <Button
              style={{
                backgroundColor: "orange",
                color: "black",
                border: "orange 2px solid",
              }}
              href="https://itsjustbolu.github.io/Weather_App/"
              target="_blank"
            >
              Blog
            </Button>{" "} */}
            <Button
              style={{
                backgroundColor: "orange",
                color: "black",
                border: "orange 2px solid",
              }}
              href="https://itsjustbolu.github.io/Real_Estate_Analysis_React/"
              target="_blank"
            >
              Demo
            </Button>{" "}
            <Button
              style={{
                backgroundColor: "orange",
                color: "black",
                border: "orange 2px solid",
              }}
              href="https://github.com/itsjustbolu/Real_Estate_Analysis_React"
              target="_blank"
            >
              Github
            </Button>
          </Card.Body>
        </Card>

        <Card
          className="card"
          style={{ width: "22rem", border: "orange 2px solid" }}
        >
          {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
          <Card.Body className="card_body">
            <Card.Title style={{ fontWeight: "bolder" }}>
              Weather App
            </Card.Title>
            <Card.Text>
              A web app that returns weather data using the OpenWeatherMap API,
              based on user's city or zip code.
              <div className="tech">Technologies: JavaScript, HTML, CSS.</div>
            </Card.Text>
            {/* <Button
              style={{
                backgroundColor: "orange",
                color: "black",
                border: "orange 2px solid",
              }}
              href="https://itsjustbolu.github.io/Weather_App/"
              target="_blank"
            >
              Blog
            </Button>{" "} */}
            <Button
              style={{
                backgroundColor: "orange",
                color: "black",
                border: "orange 2px solid",
              }}
              href="https://itsjustbolu.github.io/Weather_App/"
              target="_blank"
            >
              Demo
            </Button>{" "}
            <Button
              style={{
                backgroundColor: "orange",
                color: "black",
                border: "orange 2px solid",
              }}
              href="https://github.com/itsjustbolu/Weather_App"
              target="_blank"
            >
              Github
            </Button>
          </Card.Body>
        </Card>

        <Card
          className="card"
          style={{ width: "22rem", border: "orange 2px solid" }}
        >
          {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
          <Card.Body className="card_body">
            <Card.Title style={{ fontWeight: "bolder" }}>
              Simple Beat Maker
            </Card.Title>
            <Card.Text>
              A simple beat maker written in VanillaJS that takes keyboard
              inputs and produces sounds.
              <div className="tech">Technologies: JavaScript, HTML, CSS.</div>
            </Card.Text>
            {/* <Button
              style={{
                backgroundColor: "orange",
                color: "black",
                border: "orange 2px solid",
              }}
              href="https://itsjustbolu.github.io/Weather_App/"
              target="_blank"
            >
              Blog
            </Button>{" "} */}
            <Button
              style={{
                backgroundColor: "orange",
                color: "black",
                border: "orange 2px solid",
              }}
              href="https://itsjustbolu.github.io/Simple_Beat_Maker/"
              target="_blank"
            >
              Demo
            </Button>{" "}
            <Button
              style={{
                backgroundColor: "orange",
                color: "black",
                border: "orange 2px solid",
              }}
              href="https://github.com/itsjustbolu/Simple_Beat_Maker"
              target="_blank"
            >
              Github
            </Button>
          </Card.Body>
        </Card>

        <Card
          className="card"
          style={{ width: "22rem", border: "orange 2px solid" }}
        >
          {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
          <Card.Body className="card_body">
            <Card.Title style={{ fontWeight: "bolder" }}>
              Password Generator
            </Card.Title>
            <Card.Text>
              A handy web app written in VanillaJS that generates a password
              based on user's requirements.
              <div className="tech">Technologies: JavaScript, HTML, CSS.</div>
            </Card.Text>
            {/* <Button
              style={{
                backgroundColor: "orange",
                color: "black",
                border: "orange 2px solid",
              }}
              href="https://itsjustbolu.github.io/Weather_App/"
              target="_blank"
            >
              Blog
            </Button>{" "} */}
            <Button
              style={{
                backgroundColor: "orange",
                color: "black",
                border: "orange 2px solid",
              }}
              href="https://itsjustbolu.github.io/Password_Generator/"
              target="_blank"
            >
              Demo
            </Button>{" "}
            <Button
              style={{
                backgroundColor: "orange",
                color: "black",
                border: "orange 2px solid",
              }}
              href="https://github.com/itsjustbolu/Password_Generator"
              target="_blank"
            >
              Github
            </Button>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default ProjectsPage;
