import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./ContactPage.css";
import emailjs from "emailjs-com";
import { init } from "emailjs-com";
init("user_uJIU0ocMzuEvlURVr6rfW");

const ContactPage = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_px7md77",
        "template_3n0j9a2",
        e.target,
        "user_uJIU0ocMzuEvlURVr6rfW"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    const form_container = document.getElementById("contact_container");
    // form_container.style.display = 'none'
    form_container.remove();
    const thanks_div = document.createElement("div");

    // const contact_form = document.getElementById("contact_form");

    thanks_div.innerHTML =
      "Your message has been sent. I will be in touch with you shortly.";
    thanks_div.style.alignContent = "center";
    document.body.appendChild(thanks_div);
  }

  return (
    <div className="contact_container" id="contact_container">
      <div className="page_title">
        <h1>Contact</h1>
      </div>

      <div className="contact_form">
        <form className="contact-form" id="form_container" onSubmit={sendEmail}>
          <label>Name</label>
          <input type="text" name="sender_name" required />

          <label>Email</label>
          <input type="email" name="sender_email" required />
          <label>Message</label>
          <textarea name="sender_message" rows="7" required />
          <input className="submit_button" type="submit" value="SEND" />
        </form>

        {/* <Form>
          <Form.Group controlId="form_name" name="name">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" />
          </Form.Group>
          <Form.Group controlId="form_email" name="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" />
          </Form.Group>
          <Form.Group controlId="form_subject" name="subject">
            <Form.Label>Subject</Form.Label>
            <Form.Control type="text" />
          </Form.Group>
          <Form.Group controlId="form_message" name="message">
            <Form.Label>Message</Form.Label>
            <Form.Control as="textarea" rows={5} />
          </Form.Group>
          <Button variant="warning" id="submit_button" type="submit">
            Send Message
          </Button>
        </Form> */}
      </div>
    </div>
  );
};

export default ContactPage;
